import React from 'react';
import styled from 'styled-components';

const GridWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(12 / 1680 * 100vw)};
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  margin-top: ${props => props.theme.spacings.large};
  position: static;
  align-items: baseline;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: calc(12 / 1680 * 100vw);
    grid-column: 1 / span 12;
    margin-top: ${props => props.theme.spacings.xxLarge};
    position: relative;
  }
`

const ContentGrid = ({children, style}) => {
  return (
    <GridWrapper>
      {children}
    </GridWrapper>
  ) 
}

export default ContentGrid;