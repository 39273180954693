import React from "react"
import styled from "styled-components"
import {GatsbyImage} from 'gatsby-plugin-image'
import { P } from "./Typography"
import { useStaticQuery, graphql } from "gatsby"
import MailInput from "./mailinput"

const FooterWrapper = styled.footer`
  ${props => props.theme.footerExtendFill}
  grid-row: 3;
  position: sticky;
  width: 90vw;
  margin-top: ${props => props.theme.spacings.section};
  margin-left: auto;
  margin-right: auto;
  transition: 0.2s ease-in-out;
  padding-bottom: ${props => props.theme.spacings.medium};
  z-index: 500;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(6 / 1680 * 100vw);
  background-color: ${props => props.theme.colors.foreground};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: calc(12 / 1680 * 100vw);
  }
`

const FooterText = styled(P)`
  color: ${props => props.theme.colors.gray1};

  > a {
    color: ${props => props.theme.colors.gray1};
  }
  > a :hover {
    color: ${props => props.theme.colors.gray2};
  }
`

const MailInputSection = styled.div`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: min-content;
  column-gap: calc(12 / 1680 * 100vw);
  padding-bottom: ${props => props.theme.spacings.large};
  border-top: 0;
  margin-top: 0;
  align-self: baseline;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    padding-bottom: 0;
    // padding-bottom: 0;
  }
`

const NoCookies = styled(P)`
  color: ${props => props.theme.colors.gray1};
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  padding-top: 0;
  font-weight: 375;
  align-self: baseline;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 7 / span 6;
    grid-row: 1 / span 1;
    padding-top: ${props => props.theme.spacings.large};
  }
`

const InfoHeader = styled(P)`
  grid-row: 3;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.gray2};
  font-weight: 450;
  margin-bottom: ${props => props.theme.spacings.xSmall};
  padding-top: ${props => props.theme.spacings.xLarge};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 2;
    grid-column: 1 / span 3;
    padding-top: ${props => props.theme.spacings.large};
  }
`
const FooterChaireInfo = styled.ul`
  list-style-type: none;
  grid-row: 4;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.darkGrey};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 3;
    grid-column: 1 / span 3;
  }
`

const LinksHeader = styled(P)`
  grid-row: 5;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.gray2};
  font-weight: 450;
  margin-bottom: ${props => props.theme.spacings.xSmall};
  padding-top: ${props => props.theme.spacings.large};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 2;
    grid-column: 5 / span 2;
  }
`
const ExtLinks = styled.ul`
  list-style-type: none;
  grid-row: 6;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.secondary};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 3;
    grid-column: 5 / span 2;
  }
`

const PartnersHeader = styled(P)`
  grid-row: 7;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.gray2};
  font-weight: 450;
  margin-bottom: ${props => props.theme.spacings.xSmall};
  padding-top: ${props => props.theme.spacings.large};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 2;
    grid-column: 8 / span 2;
  }
`

const Partners = styled.ul`
  list-style-type: none;
  grid-row: 8;
  grid-column: 1 / span 6;
  color: ${props => props.theme.colors.secondary};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 3;
    grid-column: 8 / span 2;
  }
`

const UnivLogo = styled.div`
  grid-row: 9;
  grid-column: 1 / span 6;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 3 / span 4;
    grid-column: 11 / span 2;
  }
`

const Paris1Logo = styled(GatsbyImage)`
  width: 150px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  filter: invert(0.8);
`

const ListItem = styled.li`
  padding-bottom: ${props => props.theme.spacings.xSmall};
`

const Footer = ({children}) => {
  const data = useStaticQuery(graphql`
  query FooterQuery($order: [SortOrderEnum] = ASC) {
    allSanityOurlogos {
      nodes {
        logoparis1 {
          asset {
            gatsbyImageData(width: 150)
          }
        }
      }
    }
    allSanityPartner(sort: {fields: company, order: $order}) {
      edges {
        node {
          company
          website
        }
      }
    }
    allSanityExtLinks(sort: {order: ASC, fields: extAccount}) {
      edges {
        node {
          extAccount
          extUrl
        }
      }
    }
    allSanityLegal {
      nodes {
        title
        address
        credits
        publication_director
      }
    }
    allSanityMiscText {
      nodes {
        subscribe
        cookies
      }
    }
  }
`);

  const logoParis1 = data.allSanityOurlogos.nodes[0].logoparis1.asset.gatsbyImageData
  const partners = data.allSanityPartner.edges
  const extLinks = data.allSanityExtLinks.edges
  const legal = data.allSanityLegal.nodes[0]

  return (
    <FooterWrapper>
      <MailInputSection>
        <MailInput LabelText={data.allSanityMiscText.nodes[0].subscribe}/>
      </MailInputSection>
      <NoCookies context="smalltext">
        {data.allSanityMiscText.nodes[0].cookies}
      </NoCookies>
      <InfoHeader context="smalltext">
        Informations
      </InfoHeader>
      <FooterChaireInfo context="smalltext">
        <ListItem>
          <FooterText context="smalltext">
            {legal.title}
          </FooterText>
        </ListItem>
        <ListItem>
          <FooterText context="smalltext">
            {legal.address}
          </FooterText>
        </ListItem>
        <ListItem>
          <FooterText context="smalltext">
            Directrice de la publication&nbsp;: {legal.publication_director}
          </FooterText>
        </ListItem>
        <ListItem>
          <FooterText context="smalltext">
            Design et d&eacute;veloppement&nbsp;: {legal.credits}
          </FooterText>
        </ListItem>
      </FooterChaireInfo>
      <LinksHeader context="smalltext">Liens externes</LinksHeader>
      <ExtLinks>
        {extLinks.map((extLink, i) => (
          <ListItem key={i}>
            <FooterText context="smalltext">
              <a href={extLink.node.extUrl} target='_blank' rel='noopener noreferrer'>
                {extLink.node.extAccount}
              </a>
            </FooterText>
          </ListItem>
        ))}
      </ExtLinks>
      <PartnersHeader context="smalltext">Partenaires</PartnersHeader>
      <Partners>
        {partners.map((partner, i) => (
          <ListItem key={i}>
            <FooterText context="smalltext">
              <a href={partner.node.website} target='_blank' rel='noopener noreferrer'>
                {partner.node.company}
              </a>
            </FooterText>
          </ListItem>
        ))}
      </Partners>
      <UnivLogo>
        <a href="https://www.pantheonsorbonne.fr/" target='_blank' rel='noopener noreferrer'><Paris1Logo image={logoParis1} alt="Logo Paris 1" /></a>
      </UnivLogo>
    </FooterWrapper>
  ) 
}

export default Footer
