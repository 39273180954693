import styled from 'styled-components';

export const P = styled.p`
    ${props => {
        switch(props.context) {
            case "nonblock":
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
            case "block":
                return "font-size: 15px; line-height: 1.5; font-weight: 350; padding-bottom: 16px;"
            case "smalltext":
                return "font-size: 12px; line-height: 1.4; font-weight: 350; padding:0;"
            case "smalltextbubble":
                return "font-size: 12px; line-height: 1.4; font-weight: 375; padding:0;"
            case "smallinfo":  
                return "font-size: 12px; line-height: 1; font-weight: 350; text-align: left; text-transform: uppercase; padding:0;"
            case "footnote":
                return "font-size: 12px; line-height: 1.3333; font-weight: 350; padding-bottom:12px;"
            default:
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
        }
    }}
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
`

export const Li = styled.li`
    ${props => {
        switch(props.context) {
            case "nonblock":
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
            case "block":
                return "font-size: 15px; line-height: 1.5; font-weight: 350; padding-bottom: 16px;"
            case "smalltext":
                return "font-size: 12px; line-height: 1.4; font-weight: 350; padding:0;"
            case "smalltextbubble":
                return "font-size: 12px; line-height: 1.4; font-weight: 350; padding:0;"
            case "smallinfo":  
                return "font-size: 12px; line-height: 1.5; font-weight: 375; text-align: left; text-transform: uppercase; padding:0;"
            case "footnote":
                return "font-size: 12px; line-height: 1.3333; font-weight: 350; padding-bottom:12px;"
            default:
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
        }
    }}
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
`

export const Caption = styled.figcaption`
    font-size: 12px;
    font-style: italic;
    line-height: 1.3333; 
    font-weight: 350; 
    padding:0;
`

export const Button = styled.button`
    ${props => {
        switch(props.context) {
            case "nonblock":
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
            case "block":
                return "font-size: 15px; line-height: 1.5; font-weight: 350; padding-bottom: 16px;"
            case "smalltext":
                return "font-size: 12px; line-height: 1.4; font-weight: 350; padding:0;"
            case "smalltextbubble":
                return "font-size: 12px; line-height: 1.4; font-weight: 375; padding:0;"
            case "smallinfo":  
                return "font-size: 12px; line-height: 1; font-weight: 400; text-align: left; text-transform: uppercase; padding:0;"
            case "footnote":
                return "font-size: 12px; line-height: 1.3333; font-weight: 350; padding-bottom:12px;"
            default:
                return "font-size: 15px; line-height: 1; font-weight: 350; padding:0;"
        }
    }}
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    cursor: pointer;
`

export const H1 = styled.h1`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 28px;
    line-height: 1.15;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 500;
    padding-bottom: ${props => props.theme.spacings.xxLarge};
`

export const H2 = styled.h2`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 21px;
    line-height: 1.25;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 350;
    padding-bottom: ${props => props.theme.spacings.xLarge};
`
export const H3 = styled.h3`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 18px;
    line-height: 1.25;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 450;
    padding-top: ${props => props.theme.spacings.largest};
    padding-bottom: ${props => props.theme.spacings.large};
`

export const H4 = styled.h4`
    margin: ${props => props.margin ? props.margin : 0};
    font-size: 15px;
    line-height: 1.5;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    font-weight: 450;
    padding-top: ${props => props.theme.spacings.xxLarge};
    padding-bottom: ${props => props.theme.spacings.medium};
`