import React, { useState} from "react";
import styled from "styled-components";
import { P } from "../components/Typography"

const ContactFormSection = styled.form`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    padding-top: ${props => props.theme.spacings.large};
    // padding-bottom: ${props => props.theme.spacings.large};
    // padding-top: 0;
    border-top: 0;
    align-items: baseline;
  
    @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 1 / span 12;
      grid-template-columns: repeat(12,minmax(0,1fr));
      grid-auto-rows: min-content;
      column-gap: calc(12 / 1680 * 100vw);
      // border-top: 1px solid ${props => props.theme.colors.gray2};
    }
`

const Label = styled(P)`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    // font-size: 12px; 
    // line-height: 1.4; 
    // font-weight: 350;
    padding-bottom: ${props => props.theme.spacings.xSmall};
    color: ${props => props.color ? props.color : props.theme.colors.gray1};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }
`

const Input = styled.input`
    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
    font-size: 12px; 
    line-height: 1; 
    font-weight: 375;
    // padding-bottom: 0;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xxSmall};
    color: ${props => props.color ? props.color : props.theme.colors.gray1};
    background: none;
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.gray1};
    border-radius: 5px;
    // margin-top: ${props => props.theme.spacings.small};

    :focus {
      outline: none;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
        margin-top: 0;

        :hover {
          border-color: ${props => props.theme.colors.gray2};
        }
    }
`

const Submit = styled.button`
    grid-column: 5 / span 2;
    grid-row: 2 / span 1;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colors.gray1};
    border-radius: 5px;
    background: none;
    color: ${props => props.theme.colors.gray1};
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 5 / span 1;
        grid-row: 1 / span 1;
        margin-top: 0;

        :hover {
          border-color: ${props => props.theme.colors.gray2};
        }
        :hover > p {
          color: ${props => props.theme.colors.gray2};
        }
    }
`

const SubmitText = styled(P)`
    color: ${props => props.theme.colors.gray1};
    line-height: 1.2;
`

const ResponseText = styled(P)`
    color: ${props => props.theme.colors.gray1};
    grid-column: 5 / span 2;
    grid-row: 1 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 6 / span 1;
        grid-row: 1 / span 1;
    }
`

const onSubmit = async (event, setSubmitText) => {
    event.preventDefault();
    setSubmitText("Envoi...");
    const formElements = [...event.currentTarget.elements];
    const isValid =
      formElements.filter((elem) => elem.name === "bot-field")[0].value === "";
    const validFormElements = isValid ? formElements : [];
  
    if (validFormElements.length < 1) {
      setSubmitText("Erreur");
    } else {
      const filledOutElements = validFormElements
        .filter((elem) => !!elem.value)
        .map(
          (element) =>
            encodeURIComponent(element.name) +
            "=" +
            encodeURIComponent(element.value)
        )
        .join("&");
  
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: filledOutElements,
      })
        .then(() => {
          setSubmitText("Merci !");
        })
        .catch((_) => {
          setSubmitText(
            "Une erreur s'est produite"
          );
        });
    }
  };

const MailInput = ({LabelText}) => {
    const [submitText, setSubmitText] = useState(null);
    return (
        <ContactFormSection
            name="mail-input"
            method="POST"
            data-netlify="true"
            onSubmit={e => onSubmit(e, setSubmitText)}
            >
            <p style={{ display: "none" }}>
                <label>
                    <input name="bot-field" value="" readOnly />
                </label>
            </p>
            <input
                style={{ display: "none" }}
                name="form-name"
                value="mail-input"
                readOnly={true}
            />
            <Label context="smalltext">
                {LabelText}
            </Label>
            <Input type="email" id="email" name="email"/>
            <Submit type="submit" name="SendMessage">
                <SubmitText context="smalltext">Envoyer</SubmitText>
            </Submit>
            {submitText && <ResponseText context="smalltext">{submitText}</ResponseText>}
        </ContactFormSection>
    );
}

export default MailInput;