import { Link } from "gatsby"
import React, {useState} from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useScrollPosition } from "./onScroll"

const Logo = styled(Link)`
  grid-row: 1;
  position: sticky;
  transition: 0.2s ease-in-out;
  margin-bottom: ${props => props.theme.spacings.medium};
  z-index: 999;
  top: ${props => props.theme.spacings.small};
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  max-width: 150px;
  padding-top: ${props => props.theme.spacings.medium};
  background-color: ${props => props.theme.colors.background};
  border-radius: 5px;
  padding-left: ${props => props.theme.spacings.small};
  padding-right: ${props => props.theme.spacings.small};
  padding-bottom: ${props => props.theme.spacings.xSmall};

  @media ${props => props.theme.breakpoints.desktop} {
    left: 0;
    transform: translateX(0%);
    margin-left: -12px;
    margin-right: -12px;
    max-width: 150px;
  }
`

export default function Header () {

  const headerQuery = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityOurlogos {
        nodes {
          ourlogos {
            source
          }
        }
      }
    }
    `)

  const [hidden, setOpen] = useState("true");

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (currPos.y > -50) {
        setOpen(true)
      } else if (isShow !== hidden) {
          setOpen(isShow)
      }
    },
    [hidden],
    null,
    false,
    0
  )

  return (
      <Logo to="/" style={(hidden ? {opacity: "1"} : {opacity: "0"})} dangerouslySetInnerHTML={{__html: headerQuery.allSanityOurlogos.nodes[0].ourlogos[0].source}}/>
  )
}