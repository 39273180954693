import React from 'react';
import styled from 'styled-components';

const MainGridWrapper = styled.div`
  display: grid;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto 1fr auto;
  column-gap: 0;
  min-height: 100vh;
`

const MainGrid = ({children}) => {
  return (
    <MainGridWrapper>
      {children}
    </MainGridWrapper>
  ) 
}

export default MainGrid;