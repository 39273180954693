import { Link } from "gatsby"
import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { P } from "./Typography"
import { useStaticQuery, graphql } from "gatsby"
import { useScrollPosition } from "./onScroll"
import Theme from "../themes/theme"

const MenuWrapper = styled.div`
    font-family: ${props => props.theme.font.main};
    z-index: 1000;
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;

    .hidden {
        -webkit-transform: translate3d(100vw, 0, 0);
    }

    @media ${props => props.theme.breakpoints.desktop} {
        top: ${props => props.theme.spacings.xLarge};
        right: 5vw;
        bottom: auto;

        .hidden {
            -webkit-transform: translate3d(50vw, 0, 0);
        }
    }
`

const Arrow = styled.button`
    position: relative;
    float: left;
    transition: transform 0.3s ease-in-out;
    height: 26px;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.background};
    border: 0;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${props => props.theme.breakpoints.desktop} {
        background-color: transparent;
        transform: none;
        :hover {
            background-color: ${props => props.theme.colors.foreground};
        }
        :hover .cls-1 {
            fill: ${props => props.theme.colors.background};
        }
    }
`
const ArrowSvg = styled.div`
    width: 16px;
    height: auto;
    padding-top: 5px;
`

const MenuList = styled.ul`
    font-family: ${props => props.theme.font.main};
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: transform 0.3s ease-in-out;
    padding-right: 1px;
`

const MenuButton = styled(Link)`
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    display: block;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.gray3};
    border-color: ${props => props.theme.colors.foreground};
    margin-left: 2px;

    :hover {
        background-color: ${props => props.theme.colors.gray2};
    }

    @media ${props => props.theme.breakpoints.desktop} {
        box-shadow: none;
    }
`

const currentButtonColor = Theme.colors.foreground
const currentButtonTextColor = Theme.colors.background
const currentButtonBorderRadius = '5px'

function getLastPart(url) {
    var parts = url.split("/");
    return (url.lastIndexOf('/') !== url.length - 1 
       ? parts[parts.length - 1]
       : parts[parts.length - 2]);
}

export default function Menu () {

    const menuQuery = useStaticQuery(graphql`
    query MenuQuery {
      allSanityOurlogos {
        nodes {
          ourlogos {
            source
          }
        }
      }
    }
    `)

    const [hidden, setOpen] = useState(true);
    const [rotate, setRotate] = useState(true);

    const clickHandler = () => {
      setOpen(!hidden);
      setRotate(!rotate);
    };
  
    useScrollPosition(
      ({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y
        if (currPos.y > -50) {
            setOpen(true)
            setRotate(true)
        } else if (isShow !== hidden) {
            setOpen(isShow)
            setRotate(isShow)
        }
      },
      [hidden],
      null,
      false,
      0
    )

    const [currentUrl, setCurrentUrl] = useState(null);

    var urlLastPart = null

    useEffect(() => {
        urlLastPart = getLastPart(window.location.href)
        setCurrentUrl(urlLastPart)
    },[])

    return (
        <MenuWrapper id='menu-bar'>
            <Arrow id="menu-arrow" onClick={clickHandler} style={hidden ? {} : {transform: `translate3d(208px, 0, 0)`}}>
                <ArrowSvg className={(rotate ? '' : 'rotate')} dangerouslySetInnerHTML={{__html: menuQuery.allSanityOurlogos.nodes[0].ourlogos[1].source}}/>
            </Arrow>
            <MenuList id='menu' className={(hidden ? '' : 'hidden')}>
                <li id="menu-about"><MenuButton style={currentUrl === 'a-propos' ? {backgroundColor: currentButtonColor, borderColor: currentButtonColor, borderRadius: currentButtonBorderRadius, pointerEvents: 'none'} : {}} to="/a-propos"><P context="smalltextbubble" style={currentUrl === 'a-propos' ? {color: currentButtonTextColor} : {}}>À&nbsp;propos</P></MenuButton></li>
                <li id="menu-news"><MenuButton style={currentUrl === 'activites' ? {backgroundColor: currentButtonColor, borderColor: currentButtonColor, borderRadius: currentButtonBorderRadius, pointerEvents: 'none'} : {}} to="/activites"><P context="smalltextbubble" style={currentUrl === 'activites' ? {color: currentButtonTextColor} : {}}>Activités</P></MenuButton></li>
                <li id="menu-team"><MenuButton style={currentUrl === 'equipe' ? {backgroundColor: currentButtonColor, borderColor: currentButtonColor, borderRadius: currentButtonBorderRadius, pointerEvents: 'none'} : {}} to="/equipe"><P context="smalltextbubble" style={currentUrl === 'equipe' ? {color: currentButtonTextColor} : {}}>Équipe</P></MenuButton></li>
            </MenuList>
        </MenuWrapper>
    )
}