import React from "react"
import Menu from "./menu"
import Header from "./header"
import Footer from "./footer"
import MainGrid from "./MainGrid"
import ContentGrid from "./ContentGrid"

const Layout = ({ children }) => {

  return (
    <div>
      <Menu></Menu>
      <MainGrid>
        <Header></Header>
        <ContentGrid>
          {children}
        </ContentGrid>
        <Footer></Footer>
      </MainGrid>
    </div>
  )
}

export default Layout
